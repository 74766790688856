
import styles from "./TruckScene.module.scss";
import classNames from "classnames";
import { useMemo, useState} from "react";
import { HTMLMotionProps, motion } from "framer-motion";
import { mainEasing } from "@/constants/easings";

interface CloseBtnProps extends HTMLMotionProps<'div'> {
  visible: boolean;
}

export const CloseBtn = (
  {
    visible,
      ...props
  }: CloseBtnProps,
) => {
  const [isAnimated, setIsAnimated] = useState(false);

  const variants = useMemo(() => {
    const lineTransition = {
      duration: 0.5,
      ease: mainEasing,
    };
    return {
      closeBtn: {
        visible: {
          opacity: 1,
        },
        hidden: {
          opacity: 0,
        },
      },
      leftLine: {
        visible: {
          x: 0,
          transition: {
            ...lineTransition,
          },
        },
        hidden: {
          x: -92,
          transition: {
            x: {
              ...lineTransition,
              delay: 0.6,
            },
          },
        },
      },
      leftLineWrapper: {
        visible: {
          y: 2,
          rotate: 45,
          transition: {
            rotate: {
              delay: 0.7,
            },
            y: {
              delay: 0.7,
            },
          },
        },
        hidden: {
          y: 0,
          rotate: 0,
        },
      },
      rightLine: {
        visible: {
          x: 0,
          transition: {
            ...lineTransition,
          },
        },
        hidden: {
          x: 92,
          transition: {
            x: {
              ...lineTransition,
              delay: 0.6,
            },
          },
        },
      },
      rightLineWrapper: {
        visible: {
          y: -2,
          rotate: -45,
          transition: {
            rotate: {
              delay: 0.7,
            },
            y: {
              delay: 0.7,
            },
          },
        },
        hidden: {
          y: 0,
          rotate: 0,
        },
      },
    };
  }, []);

  return (
    <motion.div
      className={classNames(styles.closeBtn, {
        [styles.closeBtn_hidden]: isAnimated && !visible
      })}
      initial='hidden'
      animate={visible ? 'visible' : 'hidden'}
      onAnimationComplete={() => setIsAnimated(true)}
      {...props}
      onClick={(e: any) => {
        setIsAnimated(false);
        if (typeof props.onClick === 'function') {
          props.onClick(e);
        }
      }}
    >
      <svg width='88' height='88' viewBox='0 0 88 88' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <motion.g
          variants={variants.leftLineWrapper}
        >
          <motion.rect
            y='42'
            width='88'
            height='2'
            fill='#C9C9C9'
            variants={variants.leftLine}
          />
        </motion.g>
        <motion.g
          variants={variants.rightLineWrapper}
        >
          <motion.rect
            y='46'
            width='88'
            height='2'
            fill='#C9C9C9'
            variants={variants.rightLine}
          />
        </motion.g>
      </svg>
    </motion.div>
  );
};