import React, { useEffect, useRef, useState } from "react";
import { ITiresCategory, ITiresContacts } from "@/api/models/tires.models";
import axiosInstance from "@/api/axiosInstance";
import { PreviewSlide } from "@/components/previewSlide/PreviewSlide";
import { FullScreenSlide } from "@/components/fullScreenSlide/FullScreenSlide";
import { ContactsSlide } from "@/components/contactsSlide/ContactsSlide";
import RootLayout from "@/components/layout/Layout";
import { SliderSlide } from "@/components/sliderSlide/SliderSlide";
import { PartnersSlide } from "@/components/partnersSlide/PartnersSlide";
import { Mousewheel } from 'swiper/modules';
import { Swiper, type SwiperClass, type SwiperRef, SwiperSlide } from "swiper/react";

import 'swiper/scss';
import './TiresCategory.scss'
import 'swiper/css/mousewheel'
import { useDispatch } from "react-redux";
import { hidePullCursor } from "@/store/slices/PullCursor";
import { TruckScene } from "../truckScene/TruckScene";
import { TruckSceneMobile } from "../truckSceneMobile/TruckSceneMobile";
import { useIsMobile } from "@/hooks/useIsMobile";

export default function TiresCategory({
  pageData,
  pageContacts,
  currentTheme,
  onChangeScreen,
  onSetCustomTheme
}: {
  pageData: ITiresCategory | undefined,
  pageContacts: ITiresContacts | undefined,
  currentTheme: string,
  onChangeScreen: (activeIndex: number) => void,
  onSetCustomTheme?: (theme: string | null) => void,
}) {
  const dispatch = useDispatch();
  const baseUrl = axiosInstance.defaults.baseURL;
  const pageSwiperRef = useRef<SwiperRef>(null!);
  const [isReadyToDownloadSeq, setIsReadyToDownloadSeq] = useState(false);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    pageSwiperRef.current.swiper.slideTo(0, 0);
  }, [pageData]);

  function disableCursor() {
    dispatch(hidePullCursor());
  }

  function onTruckSceneStart() {
    pageSwiperRef.current.swiper.disable();
  }

  function onTruckSceneEnd() {
    pageSwiperRef.current.swiper.enable();
  }

  function onFirstSlideCanPlay(e: any) {
    setIsReadyToDownloadSeq(true);
  }

  return (
    <RootLayout pageTitle={pageData?.category?.title ?? ''} theme={currentTheme}>
      <div>
        <Swiper
          modules={[Mousewheel]}
          className='page-swiper'
          slidesPerView={1}
          spaceBetween={-1}
          direction="vertical"
          speed={800}
          mousewheel={{
            thresholdDelta: 50,
          }}
          ref={pageSwiperRef}
          onSlideChange={(swiper: SwiperClass) => {
            onChangeScreen(swiper.activeIndex);
            disableCursor();
          }}
        >
          <SwiperSlide key={'preview-slide'} className={'pageSLide'}>
            <PreviewSlide
              id={pageData?.category?.id ?? ''}
              prevCategory={pageData?.prev ?? ''}
              nextCategory={pageData?.next ?? ''}
              quote={pageData?.category?.quote ?? ''}
              backgroundVideoSrc={pageData?.category?.type === 'video' ? `${baseUrl}${pageData.category?.video}` : null}
              backgroundImageSrc={pageData?.category?.type === 'image' ? `${baseUrl}${pageData.category?.image}` : null}
              backgroundVideoMobileSrc={pageData?.category?.video_mobile ? `${baseUrl}${pageData.category.video_mobile}` : null}
              backgroundImageMobileSrc={pageData?.category?.image_mobile ? `${baseUrl}${pageData.category.image_mobile}` : null}
              onCanPlay={onFirstSlideCanPlay}
            />
          </SwiperSlide>
          {
            pageData?.truckScene &&
            <>
              {isMobile ? <>
                <SwiperSlide className={'pageSLide'}><TruckSceneMobile /></SwiperSlide>

                <SwiperSlide className={'pageSLide'}>
                  <FullScreenSlide
                    title={'<span class="title">Передняя <span class="italicTitle greyTitle">ось<span/></span>'}
                    desc={'Курсовая устойчивость при движении прямо и на поворотах. Шины передней оси должны соответствовать особым требованиям из-за прямого соединения с управляемой осью. Они должны обеспечивать курсовую устойчивость при движении прямо и курсовую устойчивость на поворотах.'}
                    caption={'<span class="title darkTitle">Передняя <span class="italicTitle greyTitle">ось<span/></span>'}
                    id={'truckMobile_1'}
                    backgroundVideoSrc={'/videos/truckTiresMobile/video1.mov'}
                    onSetCustomTheme={(theme) => {
                      if (onSetCustomTheme) {
                        onSetCustomTheme(theme)
                      }
                    }}
                  />
                </SwiperSlide>

                <SwiperSlide className={'pageSLide'}>
                  <FullScreenSlide
                    title={'<span class="title">Ведущая  <span class="italicTitle greyTitle">ось<span/></span>'}
                    desc={'Качественные тягово-сцепные характеристики, повышенная устойчивость, экономичность. Ведущие шины автомобиля обычно несут наибольшую нагрузку. Они должны выводить на дорогу тягу для ускорения, а также передавать тормозные силы и замедление через двигатель на землю при отпускании акселератора.'}
                    caption={'<span class="title darkTitle">Ведущая <span class="italicTitle greyTitle">ось<span/></span>'}
                    id={'truckMobile_2'}
                    backgroundVideoSrc={'/videos/truckTiresMobile/video2.mov'}
                    onSetCustomTheme={(theme) => {
                      if (onSetCustomTheme) {
                        onSetCustomTheme(theme)
                      }
                    }}
                  />
                </SwiperSlide>

                <SwiperSlide className={'pageSLide'}>
                  <FullScreenSlide
                    title={'<span class="title">Прицепная <span class="italicTitle greyTitle">ось<span/></span>'}
                    desc={'Экономичность, распределение веса. Их задача состоит в том, чтобы распределить вес автомобиля и передать большее тормозное усилие на прицеп, а также придать автомобилю боковую устойчивость в случае боковых сил.'}
                    caption={'<span class="title darkTitle">Прицепная <span class="italicTitle greyTitle">ось<span/></span>'}
                    id={'truckMobile_3'}
                    backgroundVideoSrc={'/videos/truckTiresMobile/video3.mov'}
                    onSetCustomTheme={(theme) => {
                      if (onSetCustomTheme) {
                        onSetCustomTheme(theme)
                      }
                    }}
                  />
                </SwiperSlide>

                <SwiperSlide className={'pageSLide'}>
                  <FullScreenSlide
                    title={'<span class="title">Универсальная <span class="italicTitle greyTitle">ось<span/></span>'}
                    desc={'Особенно низкое сопротивление качению, стойкость к порезам. Особенно низкое сопротивление качению и индивидуальное специальное оснащение, такое как особенно стойкая к порезам резиновая смесь делают такие шины идеальным выбором для вашего автомобиля.'}
                    caption={'<span class="title darkTitle">Универсальная <span class="italicTitle greyTitle">ось<span/></span>'}
                    id={'truckMobile_4'}
                    backgroundVideoSrc={'/videos/truckTiresMobile/video4.mov'}
                    onSetCustomTheme={(theme) => {
                      if (onSetCustomTheme) {
                        onSetCustomTheme(theme)
                      }
                    }}
                  />
                </SwiperSlide>

                <SwiperSlide className={'pageSLide'}>
                  <FullScreenSlide
                    title={'<span class="title">Зимние <span class="italicTitle greyTitle">шины<span/></span>'}
                    desc={'Качественные тягово-сцепные характеристики на заснеженных и обледенелых дорогах, курсовая устойчивость на поворотах в зимних условиях. Зимние шины становятся все более и более важными. Благодаря более глубокому профилю и сложной структуре ламелей вы и ваш автомобиль идеально подготовлены к холодному сезону с зимними шинами.'}
                    caption={'<span class="title darkTitle">Зимние <span class="italicTitle greyTitle">шины<span/></span>'}
                    id={'truckMobile_5'}
                    backgroundVideoSrc={'/videos/truckTiresMobile/video5.mov'}
                    onSetCustomTheme={(theme) => {
                      if (onSetCustomTheme) {
                        onSetCustomTheme(theme)
                      }
                    }}
                  />
                </SwiperSlide>
              </>
                :
                <SwiperSlide className={'pageSLide'}>
                  {({ isActive }) => (
                    <TruckScene
                      isActive={isActive}
                      onStart={onTruckSceneStart}
                      onEnd={onTruckSceneEnd}
                      isReadyToDownloadSeq={isReadyToDownloadSeq}
                    />)}
                </SwiperSlide>}
            </>
          }

          {
            pageData?.category?.info_blocks?.length
              ?
              pageData.category.info_blocks.map((infoBlock) => (
                <SwiperSlide key={`info-block-${infoBlock.id}`}>
                  <FullScreenSlide
                    key={`info-block-${infoBlock.id}`}
                    id={`info-block-${infoBlock.id}`}
                    backgroundVideoSrc={infoBlock.type === 'video' ? `${baseUrl}${infoBlock.video}` : null}
                    backgroundImageSrc={infoBlock.type === 'image' ? `${baseUrl}${infoBlock.image}` : null}
                    title={infoBlock.title ?? ''}
                    caption={infoBlock.caption ?? ''}
                    desc={infoBlock.desc ?? ''}
                    onSetCustomTheme={(theme) => {
                      if (onSetCustomTheme) {
                        onSetCustomTheme(theme)
                      }
                    }}
                  />
                </SwiperSlide>
              ))
              :
              ''
          }

          {
            pageData?.category?.sliders?.length
              ?
              <SwiperSlide key={'banners'}>
                <SliderSlide
                  slides={pageData.category.sliders}
                  title={pageData?.category?.slider_title ?? ''}
                />
              </SwiperSlide>
              :
              ''
          }

          {
            pageData?.category?.partners?.length
              ?
              <SwiperSlide key={'partners'}>
                <PartnersSlide partners={pageData.category.partners} />
              </SwiperSlide>
              :
              ''
          }

          {
            pageContacts
              ?
              <SwiperSlide key={'contacts'}>
                <ContactsSlide contacts={pageContacts ?? undefined} />
              </SwiperSlide>
              :
              ''
          }

        </Swiper>
      </div>
    </RootLayout >
  )
}