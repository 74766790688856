import classNames from "classnames";
import styles from "./TruckScene.module.scss";
import { ForwardedRef, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { SVGMotionProps, motion } from "framer-motion";
import { useDistanceTo } from "@/hooks/useDistanceTo";

interface PointProps extends SVGMotionProps<SVGGElement> {
  visible: boolean;
  isAnimated: boolean;
  onAnimated: () => void;
}

export const PulsePoint = forwardRef((
  {
    visible,
    isAnimated,
    onAnimated,
    children,
    ...props
  }: PointProps,
  forwardedRef: ForwardedRef<SVGGElement>,
) => {
  const pointWrapper = useRef<SVGGElement | null>(null);
  const vectorToPoint = useDistanceTo(pointWrapper);

  const catchButtonDistance = 100;
  const [isButtonCaught, setIsButtonCaught] = useState(false);

  useEffect(() => {
    if (vectorToPoint.distance > catchButtonDistance && isButtonCaught) {
      setIsButtonCaught(false);
    }
  }, [vectorToPoint, isButtonCaught]);

  const variants = useMemo(() => {
    const buttonTransition = {
      duration: 1.2,
      ease: 'circOut',
    };
    return {
      outerCircle: {
        active: {
          x: vectorToPoint.x,
          y: vectorToPoint.y,
          transition: buttonTransition,
        },
        inactive: {
          x: 0,
          y: 0,
        },
      },
      innerCircle: {
        active: {
          x: vectorToPoint.x,
          y: vectorToPoint.y,
          transition: { ...buttonTransition, duration: 0.7 },
        },
        inactive: {
          x: 0,
          y: 0,
        },
      },
      point: {
        visible: {
          // r: 15,
          scale: 1,
          transition: {
            type: "spring",
            stiffness: 50,
          },
        },
        hidden: {
          // r: 0,
          scale: 0,
          transition: {
            delay: 0.2,
          },
        },
      },
      pointBorder: {
        visible: {
          r: 48,
          transition: {
            type: "spring",
            stiffness: 100,
            delay: 0.2,
          },
        },
        hidden: {
          r: 0,
        },
      },
      pointPulse: {
        animate: {
          scale: 1.5,
          opacity: 0,
          transition: {
            opacity: {
              repeat: Infinity,
              ease: 'easeOut',
              duration: 1.5,
              repeatDelay: 1.5,
              delay: 1.5,
              from: 1,
            },
            scale: {
              repeat: Infinity,
              ease: 'easeOut',
              duration: 1.5,
              repeatDelay: 1.5,
              delay: 1.5,
              from: 1,
            },
          },
        },
        stop: {
          scale: 1,
          // Motion framer has a bug that impede to set opacity: 0
          opacity: 1,
        },
      },
      pulseWrapper: {
        visible: {
          opacity: 1,
          transition: {
            duration: 1,
            delay: 0.5,
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            duration: 0.1,
            delay: 0,
          },
        },
      },
    }
  }, [vectorToPoint]);

  function onButtonMouseEnter() {
    setIsButtonCaught(true);
  }

  function setPointRefs(el: SVGGElement | null) {
    if (typeof forwardedRef === 'function') {
      forwardedRef(el);
    } else if (forwardedRef) {
      forwardedRef.current = el;
    }
    pointWrapper.current = el;
  }

  return (
    <motion.g
      ref={setPointRefs}
      className={classNames(styles.pointSvg, {
        [styles.pointSvg_hidden]: isAnimated && !visible
      })}
      {...props}
      onMouseEnter={(e: any) => {
        onButtonMouseEnter();
        if (typeof props.onMouseEnter === 'function') {
          props.onMouseEnter(e);
        }
      }}
    >
      <motion.g
        initial='inactive'
        animate={isButtonCaught ? 'active' : 'inactive'}
        variants={variants.outerCircle}
      >
        <svg
          width='140'
          height='140'
          viewBox='0 0 140 140'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <motion.circle
            cx='70'
            cy='70'
            r='48'
            stroke='white'
            strokeOpacity='0.5'
            strokeWidth='2'
            variants={variants.pointBorder}
            initial='hidden'
            animate={visible ? 'visible' : 'hidden'}
            onAnimationComplete={() => onAnimated()}
          />
          {/* circle to cover the whole area of the svg to make it interactable */}
          <circle cx='70' cy='70' r='70' opacity='0' fill='white'/>
        </svg>
      </motion.g>
      <motion.g
        initial='inactive'
        animate={isButtonCaught ? 'active' : 'inactive'}
        variants={variants.innerCircle}
      >
        <svg
          width='140'
          height='140'
          viewBox='0 0 140 140'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <motion.g
            variants={variants.point}
            initial='hidden'
            animate={visible ? 'visible' : 'hidden'}
          >
            {children ?
              children :
              <circle
                cx='70'
                cy='70'
                r='15'
                fill='#D9D9D9'
              />
            }
          </motion.g>
        </svg>
      </motion.g>
      <motion.g
        initial='inactive'
        animate={isButtonCaught ? 'active' : 'inactive'}
        variants={variants.outerCircle}
      >
        <svg
          width='140'
          height='140'
          viewBox='0 0 140 140'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <motion.g
            initial='hidden'
            animate={visible ? 'visible' : 'hidden'}
            variants={variants.pulseWrapper}
          >
            <motion.circle
              cx='70'
              cy='70'
              r='48'
              stroke='white'
              strokeOpacity='0.3'
              initial='stop'
              animate={visible ? 'animate' : 'stop'}
              variants={variants.pointPulse}
            />
          </motion.g>
        </svg>
      </motion.g>
    </motion.g>
  );
});