import sendRequest from "@/api/sendRequest";
import {TMenu} from "@/api/models/menu.models";

export function getMenu (): Promise<TMenu> {
    const url = '/api/menu';
    const method = 'GET';

    return new Promise((resolve, reject) => {
        sendRequest(url, method)
            .then((response) => {
                resolve(response as TMenu ?? []);
            })
            .catch((e) => {
                reject(e);
            });
    });
}