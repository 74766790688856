import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './RootState';

export const partnerFormSlice = createSlice({
  name: 'partnerForm',
  initialState: {
    isOpen: false,
  },
  reducers: {
    toggleForm: (state) => {
      state.isOpen = !state.isOpen;
    },
    closeForm: (state) => {
        state.isOpen = false;
    }
  },
});

export const { toggleForm, closeForm } = partnerFormSlice.actions;

export const selectIsOpen = (state: RootState) => state.partnerForm.isOpen;

export default partnerFormSlice.reducer;
