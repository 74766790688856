import { configureStore } from '@reduxjs/toolkit';
import menuSliceReducer from './slices/Menu';
import partnerFormReducer from './slices/PartnerFormSlice';
import pullCursorReducer from './slices/PullCursor';

const reducer = {
  menu: menuSliceReducer,
  partnerForm: partnerFormReducer,
  pullCursor: pullCursorReducer,
};

export const store = configureStore({
  reducer,
});
