import { SubTitle } from "@/atoms/subTitle/SubTitle"
import classNames from "classnames"
import { Link } from "react-router-dom"
import styles from "./ContactsSlide.module.scss"
import { ITiresContacts } from "@/api/models/tires.models";

export const ContactsSlide = ({ contacts }: { contacts: ITiresContacts | undefined }) => {
  return (
    <div>
      <div className={classNames(styles.pageSection, styles.pageSection__contacts)}>
        <span dangerouslySetInnerHTML={{ __html: contacts?.contacts_title ?? '' }}>
        </span>

        <div className={styles.contactsSection}>
          <div className={styles.contactsSection__item}>
            <SubTitle>Телефон</SubTitle>
            <div className={styles.contactsSection__innerContainer}>
              {
                contacts?.phones?.length
                  ?
                  contacts.phones.map((phone, index) => (
                    <p key={index}>
                      <a href={`tel:${phone}`}>{phone}</a>
                    </p>
                  ))
                  :
                  ''
              }
            </div>
          </div>

          <div className={styles.contactsSection__item}>
            <SubTitle>Офис</SubTitle>
            <div className={styles.contactsSection__innerContainer}>
              <p>{contacts?.address ?? ''}</p>
            </div>
          </div>


          {/* <div className={styles.contactsSection__item}>
            <SubTitle>Менеджеры сегмента автомобильных шин</SubTitle>
            <div className={classNames(styles.contactsSection__innerContainer, styles.contactsSection__innerDoubleContainer)}>
              {
                contacts?.managers?.length
                  ?
                  contacts.managers.map((manager, index) => (
                    <div key={index} className={styles.contactsSection__innerContainer}>
                      <p>{manager.name}</p>
                      <div className={styles.smallContactBlock__content}>
                        <p className={styles.lightText}>
                          <a href={`tel:${manager.phone}`}>{manager.phone}</a>
                        </p>
                        <p className={styles.lightText}>
                          <a href={`mailto:${manager.email}`}>{manager.email}</a>
                        </p>
                      </div>
                    </div>
                  ))
                  :
                  ''
              }
            </div>
          </div> */}

          {/* <div className={styles.contactsSection__item}>
            <SubTitle>Для отзывов по качеству обслуживания</SubTitle>
            <div className={styles.contactsSection__innerContainer}>
              <p>
                <a href={`tel:${contacts?.review_phone}`}>
                  {contacts?.review_phone}
                </a>
              </p>
              <p>
                <a href={`mailto:${contacts?.review_email}`}>
                  {contacts?.review_email}
                </a>
              </p>
            </div>
          </div> */}

          <div className={styles.contactsSection__itemsWrapper}>
            <div className={styles.contactsSection__item}>
              <SubTitle>Электронная почта</SubTitle>
              <div className={styles.contactsSection__innerContainer}>
                {
                  contacts?.emails?.length
                    ?
                    contacts.emails.map((email, index) => (
                      <p key={index}>
                        <a href={`mailto:${email}`}>{email}</a>
                      </p>
                    ))
                    :
                    ''
                }
              </div>
            </div>


            {/* <div className={styles.contactsSection__item}>
              <SubTitle>По вопросам сотрудничества</SubTitle>
              <div className={styles.contactsSection__innerContainer}>
                {
                  contacts?.coop_emails?.length
                    ?
                    contacts.coop_emails.map((email, index) => (
                      <p key={index}>
                        <a href={`mailto:${email}`}>{email}</a>
                      </p>
                    ))
                    :
                    ''
                }
              </div>
            </div> */}
          </div>
        </div>
        <Link to={'https://zgalex.com/'} className={styles.footerLink}>Сделано в ZG.Agency</Link>
      </div>
    </div>
  )
}