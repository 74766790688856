import sendRequest from "@/api/sendRequest";
import {ILegalInformationPage} from "@/api/models/legal_information.models";

export function getLegalInformationPage (): Promise<ILegalInformationPage> {
    const url = '/api/page/legalinformation';
    const method = "GET";

    return new Promise((resolve, reject) => {
        sendRequest(url, method)
            .then((response) => {
                resolve(response as ILegalInformationPage ?? {});
            })
            .catch((e) => {
                reject(e);
            });
    });
}
