import { AxiosRequestConfig, Method } from 'axios';
import instance from "@/api/axiosInstance";

export default function sendRequest(
    url: string,
    method: Method,
    data: unknown = {}
) {
    const config: AxiosRequestConfig = {
        url,
        method,
        data
    };

    return new Promise((resolve, reject) => {
        instance.request(config)
            .then((value) => {
                if (value.status !== 200) {
                    reject(new Error('Unknown Error'));
                }
                resolve(value.data);
            })
            .catch((reason) => {
                reject(reason);
            });
    });
}
