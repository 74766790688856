import classNames from "classnames";
import styles from "./TruckScene.module.scss";
import { motion } from "framer-motion";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { mainEasing } from "@/constants/easings";

export const TextCarousel = ({
  element,
  delay = 0,
}: {
  element: ReactNode,
  delay?: number,
}) => {
  const containerRef = useRef<HTMLDivElement>(null!);
  const containerNextRef = useRef<HTMLDivElement>(null!);
  const [height, setHeight] = useState(0);
  const [title, setTitle] = useState<ReactNode[]>([]);
  const [isAnimated, setIsAnimated] = useState(false);

  const isBeingChanged = useCallback(() => {
    return title.length > 1;
  }, [title]);

  useEffect(() => {
    // Block changes in the transition state
    // if (isBeingChanged() || isAnimated) {
    //   return;
    // }
    setTitle((prev) => {
      if (prev[prev.length - 1] === element) {
        return prev;
      }
      return [...prev, element];
    });
    Promise.resolve().then(() => setHeight(
      containerNextRef.current ? containerNextRef?.current.offsetHeight : containerRef?.current.offsetHeight
    ));
  }, [isAnimated, isBeingChanged, element]);

  useEffect(() => {
    if (!isAnimated) {
      return;
    }
    if (title.length !== 1) {
      setTitle((prev) => prev.filter((_, idx) => idx !== 0));
    } else {
      setIsAnimated(false);
    }
  }, [isAnimated, title]);

  const variants = useMemo(() => {
    return {
      outerContainer: {
        default: {
          height,
          transition: {
            delay: 0.5 + delay,
            duration: 1,
            ease: mainEasing,
          },
        },
      },
      current: {
        changed: {
          y: 0,
          transition: {
            duration: 0,
          },
        },
        change: {
          y: '-105%',
          transition: {
            delay,
            duration: 1,
            ease: mainEasing,
          },
        },
      },
      next: {
        changed: {
          y: `max(100%, ${height}px)`,
          transition: {
            duration: 0,
          },
        },
        change: {
          y: 0,
          transition: {
            delay: 1 + delay,
            duration: 1,
            ease: mainEasing,
          },
        },
      }
    };
  }, [height, delay]);

  useEffect(() => {
    setHeight(containerRef?.current.offsetHeight);
  }, [containerRef]);

  return (
    <motion.div
      className={classNames(styles.textCarousel)}
      variants={variants.outerContainer}
      animate='default'
    >
      <motion.div
        ref={containerRef}
        className={classNames(styles.textCarouselContainer)}
        variants={variants.current}
        initial='changed'
        animate={isBeingChanged() && !isAnimated ? 'change' : 'changed'}
      >
        {!isAnimated ? title[0] : title[1]}
      </motion.div>
      {isBeingChanged() &&
        <motion.div
          ref={containerNextRef}
          className={classNames(styles.titleContainer)}
          variants={variants.next}
          initial='changed'
          animate={isBeingChanged() ? 'change' : 'changed'}
          onAnimationComplete={() => setIsAnimated(true)}
        >
          {title[1]}
        </motion.div>
      }
    </motion.div>
  );
}