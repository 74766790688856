import styles from './SeqPlayer.module.scss';
import { ForwardedRef, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { clamp, debounce, fitCover, parseSeparatedNumbers, prependZeros } from './SeqPlayer.helpers';
import type {
  FrameSize,
  CanvasImage,
  Frame,
  CanvasImageGroup,
  PromiseQueue,
  Segment,
  SeqOptions,
  SeqPlayerProps,
  Sequence,
  Transition,
  TransitionOpts,
} from './SeqPlyaer.types';
import { defaultTransition } from './SeqPlayer.transitions';

/**
 * Sequence Player Component.
 * 
 * Feature Roadmap
 * [+] loaded event
 * [+] loading progress event
 * [+] implement the method 'once' to execute events only once
 * [-] switch to the loading state if the playing sequence is not loaded yet
 * [-] support image sets for different resolutions
 * [+] transition between sequences
 * [-] backward playing
 * [+] control frame rate from props
 * 
 * @dispatches [loading:(start|progress|end), transition:(start|end), progress:(single number or range between 0 and 1), end]
 * @author Chistyakov Ilya <ichistyakovv@gmail.com>
 */

export const SeqPlayer = forwardRef((
  props: SeqPlayerProps,
  forwardedRef: ForwardedRef<HTMLCanvasElement>,
) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const seqName = useRef(props.startSequence);
  const events = useRef(new EventTarget());
  const transitions = useRef<Transition[]>([]);
  const frame = useRef<Frame>({ previous: 0, current: 0 });
  const frameDeltaTime = useMemo(() => 1000 / props.frameRate, [props.frameRate]);
  const [sequences, setSequences] = useState<Record<string, Sequence>>(props.sequenceSet.reduce((acc, cur) => ({
    ...acc,
    [cur.name]: { images: [], frameCount: 1 },
  }), {}));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingQueue, setLoadingQueue] = useState(props.sequenceSet);
  const [frameSize, setFrameSize] = useState<FrameSize>({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });
  const [renderFrame, setRenderFrame] = useState<Frame>({ previous: 0, current: 0 });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [seqToLoad, setSeqToLoad] = useState(props.sequenceSet.length);
  const seqLoaded = useRef(0);
  const deltaFrame = useRef(1);
  const isLastFrame = useRef(false);
  const isPlay = useRef(false);
  const isLoop = useRef(false);
  const isTransition = useRef(false);
  const isLoaded = useRef(false);

  const sequenceEvents = useRef<Record<string, EventTarget>>(props.sequenceSet.reduce((acc, cur) => ({
    ...acc,
    [cur.name]: new EventTarget(),
  }), {}));

  const seqOptions = useRef<Record<string, SeqOptions>>(props.sequenceSet.reduce((acc, cur) => ({
    ...acc,
    [cur.name]: cur,
  }), {}));

  const progressBreakpoints = useRef<Record<string, Segment[]>>(props.sequenceSet.reduce((acc, cur) => ({
    ...acc,
    [cur.name]: [],
  }), {}));

  useEffect(() => {
    const onResize = debounce(() => {
      setFrameSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    }, 100);

    window.addEventListener('resize', onResize);
    // Move dispatching to the microtask phase
    Promise.resolve().then(() => events.current.dispatchEvent(new CustomEvent('loading:start')));
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const calcSizes = useCallback((
    img: HTMLImageElement,
    frameSize: FrameSize,
  ): CanvasImage => {
    const {
      width: scaledWidth,
      height: scaledHeight
    } = fitCover(img.width, img.height, frameSize.width, frameSize.height);
    return {
      orig: img,
      width: scaledWidth,
      height: scaledHeight,
      dx: (frameSize.width - scaledWidth) / 2,
      dy: (frameSize.height - scaledHeight) / 2,
    };
  }, []);

  const recalcSizes = useCallback((
    images: CanvasImage[],
    frameSize: FrameSize,
  ): CanvasImage[] => {
    return images.map((item) => calcSizes(item.orig, frameSize));
  }, [calcSizes]);

  const resetState = () => {
    frame.current = {
      previous: 0,
      current: 0,
    };
    isLoop.current = false;
    isPlay.current = false;
    isLastFrame.current = false;
  };

  const getLastFrame = () => {
    return seqOptions.current[seqName.current].frameCount - 1;
  };

  // const triggerRender = () => {
  //   setRenderFrame((prev) => ({
  //     previous: frame.current.previous,
  //     current: frame.current.current,
  //   }));
  // };

  const promises: PromiseQueue = useMemo(() => {
    const promises: PromiseQueue = { named: {}, all: [] };
    for (let i = 0; i < loadingQueue.length; i++) {
      promises.named[loadingQueue[i].name] = [];
      const endFrame = loadingQueue[i].startFrame + loadingQueue[i].frameCount;
      for (let j = loadingQueue[i].startFrame; j < endFrame; j++) {
        const promise: Promise<CanvasImage> = new Promise((resolve, reject) => {
          const path = `${loadingQueue[i].path}${prependZeros(j, loadingQueue[i].minNumerationLen ?? 3)}.${props.extension}`;
          const img = new Image();
          img.onload = () => resolve(calcSizes(img, frameSize));
          img.src = path;
        });
        // promise.then((data) => {
        //   setFramesLoaded((prev) => prev + 1);
        //   return data;
        // });
        promises.named[loadingQueue[i].name].push(promise);
      }
      const promiseGroup: Promise<CanvasImageGroup> = Promise
        .all(promises.named[loadingQueue[i].name])
        .then((loadedImages) => {
          seqLoaded.current++;
          const progress = seqLoaded.current / seqToLoad;
          events.current.dispatchEvent(new CustomEvent('loading:progress', {
            detail: {
              progress,
            }
          }));
          return {
            name: loadingQueue[i].name,
            images: loadedImages,
            frameCount: loadingQueue[i].frameCount,
          };
        });
      promises.all.push(promiseGroup);
    }
    return promises;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Show the first frame immediately when it's loaded
    if (promises.named[seqName.current].length > 0) {
      promises.named[seqName.current][0].then((img) => {
        // Set the first frame only if images haven't been loaded yet
        if (sequences[seqName.current].images.length === 0) {
          setSequences((prev) => ({ ...prev, [seqName.current]: { images: recalcSizes([img], frameSize), frameCount: 1 } }));
          setRenderFrame((prev) => ({ previous: prev.current, current: 0 }));
        }
      });
    }
    // Initialize sequences when all images are loaded
    Promise.all(promises.all).then((groups): void => {
      setSequences(groups.reduce((acc, cur) => ({
        ...acc,
        [cur.name]: { images: recalcSizes(cur.images, frameSize), frameCount: cur.frameCount }
      }), {}));
      isLoaded.current = true;
      events.current.dispatchEvent(new CustomEvent('loading:end'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promises]);

  useEffect(() => {
    // Frame changing loop
    let timer: string | number | NodeJS.Timeout | undefined;
    const render = () => {
      // if (isTransition || (isPlay && (!isLastFrame.current || isLoop))) {
        // If the sequence is not being played, just preserve the current state,
        // which still triggers frame rerendering, because the frame object has been changed
        setRenderFrame((prev) => ({
          previous: frame.current.previous,
          current: frame.current.current,
        }));
        const lastFrame = getLastFrame();
        const isEnd = frame.current.current === lastFrame;
        frame.current = {
          previous: isPlay.current ? frame.current.current : frame.current.previous,
          current: isPlay.current ?
            (frame.current.current + (isEnd && !isLoop.current ? 0 : deltaFrame.current)) % sequences[seqName.current].frameCount :
            clamp(frame.current.current, 0, seqOptions.current[seqName.current].frameCount - 1),
        };
        timer = setTimeout(render, frameDeltaTime);
      // } else {
      //   clearTimeout(timer);
      // }
    };
    render();
    return () => clearTimeout(timer);
  }, [isPlay, isLastFrame, isLoop, frameDeltaTime, sequences, seqName, isTransition, deltaFrame]);

  useEffect(() => {
    // Render current frame
    // Check (imgages.length - 1 < frame) if the message about missing image is not needed
    if (renderFrame.current < 0 || sequences[seqName.current].images.length === 0) {
      return;
    }
    const img = sequences[seqName.current].images[renderFrame.current];
    if (!img) {
      console.error(`Missing image for frame ${renderFrame.current}`);
      return;
    }
    if (ctxRef.current) {
      ctxRef.current.clearRect(0, 0, frameSize.width, frameSize.height);
      if (isTransition.current) {
        const transition = transitions.current[0];
        if (!transition.isStarted) {
          transition.isStarted = true;
          // Dispatch the start transition event of the whole queue
          events.current.dispatchEvent(new CustomEvent('transition:start'));
        }
        if (transition.startTime === 0) {
          // Remember last state
          transition.freezedFrame.sequenceName = seqName.current;
          transition.freezedFrame.frame = renderFrame.current;
          // Change the current sequence
          seqName.current = transition.seqName;
          // Reset the player state
          resetState();
          // Apply a new state by calling the callback
          transition.startCallback && transition.startCallback();
          transition.startTime = Date.now();
          if (transition.name) {
            // Dispatch the start transition event of a named transition
            events.current.dispatchEvent(new CustomEvent('transition:start', {
              detail: { name: transition.name },
            }));
          }
        }
        let progress = clamp((Date.now() - transition.startTime) / transition.duration, 0, 1);
        if (progress === 1) {
          transitions.current.splice(0, 1);
          if (transitions.current.length === 0) {
            isTransition.current = false;
            // Dispatch the end transition event of the whole queue
            events.current.dispatchEvent(new CustomEvent('transition:end'));
          } else {
            transitions.current[0].isStarted = true;
          }
          if (transition.name) {
            // Dispatch the end transition event of a named transition
            events.current.dispatchEvent(new CustomEvent('transition:end', {
              detail: { name: transition.name },
            }));
          }
        }
        const transitionFn = transition.fn ?? defaultTransition;
        transitionFn(
          ctxRef.current,
          progress,
          {
            freezed: sequences[transition.freezedFrame.sequenceName].images[transition.freezedFrame.frame],
            current: sequences[seqName.current].images[renderFrame.current],
          },
        );
      } else {
        ctxRef.current.drawImage(
          img.orig,
          0,
          0,
          img.orig.width,
          img.orig.height,
          img.dx,
          img.dy,
          img.width,
          img.height,
        );
      }
    }
    // Set isLastFrame flag and stop playing when the last frame is reached
    const lastFrame = getLastFrame();
    if (renderFrame.current === lastFrame && !isLastFrame.current) {
      isLastFrame.current = true;
      if (!isLoop.current) {
        isPlay.current = false;
      }
      sequenceEvents.current[seqName.current].dispatchEvent(new CustomEvent('end'));
    } else if (renderFrame.current !== lastFrame && isLastFrame.current) {
      isLastFrame.current = false;
    }
    // Dispatch progress sequenceEvents
    const frameDiff = renderFrame.current - renderFrame.previous;
    if (Math.abs(frameDiff) > 0) {
      for (let i = 0; i < progressBreakpoints.current[seqName.current].length; i++) {
        const segment = progressBreakpoints.current[seqName.current][i];
        // TODO(Ilya): implement dispatching sequenceEvents in both sides (forward and backward)
        if (renderFrame.current >= segment.start && renderFrame.previous <= segment.end) {
          const progress =
            Math.max(renderFrame.current - segment.start, 0.0001) / Math.max(segment.end - segment.start, 0.0001);
          sequenceEvents.current[seqName.current].dispatchEvent(
            new CustomEvent(`progress:${segment.start}-${segment.end}`,
            {
              detail: {
                progress,
              },
            },
          ));
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ctxRef,
    renderFrame,
    sequences,
    frameSize,
    seqName,
    progressBreakpoints,
    sequenceEvents,
    isTransition,
  ]);

  useEffect(() => {
    // Resize images on window resize
    const seq: Record<string, Sequence> = {};
    for (let name in sequences) {
      seq[name] = {
        images: recalcSizes(sequences[name].images, frameSize),
        frameCount: sequences[name].frameCount
      };
    }
    setSequences(seq);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frameSize, recalcSizes]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    // Export API
    Object.assign(canvasRef.current, {
      player: {
        progressEventPrefix: 'progress:',
        setSequence(name: string) {
          seqName.current = name;
          resetState();
        },
        setIsLoop(loop: boolean) {
          isLoop.current = loop;
        },
        play() {
          isPlay.current = true;
        },
        pause() {
          isPlay.current = false;
        },
        setProgress(progress: number) {
          progress = clamp(progress, 0, 1);
          frame.current = {
            previous: frame.current.current,
            current: Math.round(sequences[seqName.current].frameCount * progress),
          };
        },
        transition(
          sequenceName: string,
          opts: TransitionOpts,
        ) {
          transitions.current.push({
            seqName: sequenceName,
            startTime: 0,
            duration: opts.duration ?? 500,
            isStarted: false,
            freezedFrame: {
              sequenceName: seqName.current,
              frame: renderFrame.current,
            },
            fn: opts.fn,
            name: opts.name,
            startCallback: opts.startCallback,
          });
          isTransition.current = true;
        },
        on(eventName: string, fn: (...args: any[]) => void) {
          events.current.addEventListener(eventName, fn);
        },
        onSequence(sequenceName: string, eventName: string, fn: (...args: any[]) => void) {
          if (eventName.startsWith(this.progressEventPrefix)) {
            const segment: Segment = this._parseSegment(
              eventName.substring(this.progressEventPrefix.length),
              sequenceName,
            );
            // TODO(Ilya): Optimize storing segments by putting them into a hashtable
            // having the key as a progress range and the value as a an object of a
            // counter of such intervals and the segment instance itself.
            // Example: { '0.5-0.8': { counter: 2, segment: { start: 0.5, end: 0.8 } } }
            // This optimization will eliminate checking the same segments but with
            // different event handler.
            progressBreakpoints.current[sequenceName].push(segment);
            sequenceEvents.current[sequenceName]?.addEventListener(
              `${this.progressEventPrefix}${segment.start}-${segment.end}`,
              fn,
            );
          } else {
            sequenceEvents.current[sequenceName]?.addEventListener(eventName, fn);
          }
        },
        off(eventName: string, fn: (...args: any[]) => void) {
          events.current.removeEventListener(eventName, fn);
        },
        offSequence(sequenceName: string, eventName: string, fn: (...args: any[]) => void) {
          if (eventName.startsWith(this.progressEventPrefix)) {
            const segment: Segment = this._parseSegment(
              eventName.substring(this.progressEventPrefix.length),
              sequenceName,
            );
            for (let i = 0; i < progressBreakpoints.current[sequenceName].length; i++) {
              const breakpoint = progressBreakpoints.current[sequenceName][i];
              if (breakpoint.start === segment.start && breakpoint.end === segment.end) {
                progressBreakpoints.current[sequenceName].splice(i, 1);
                break;
              }
            }
            sequenceEvents.current[sequenceName]?.removeEventListener(
              `${this.progressEventPrefix}${segment.start}-${segment.end}`,
              fn,
            );
          } else {
            sequenceEvents.current[sequenceName]?.removeEventListener(eventName, fn);
          }
        },
        onceSequence(sequenceName: string, eventName: string, fn: (...args: any[]) => void) {
          const wrapper = (...args: any[]) => {
            fn(...args);
            this.offSequence(sequenceName, eventName, wrapper);
          };
          this.onSequence(sequenceName, eventName, wrapper);
        },
        _parseSegment(range: string, sequenceName: string): Segment {
            const segment = parseSeparatedNumbers(range);
            if (segment.length === 0) {
              throw new Error(`invalid segment in segment event: ${range}`);
            }
            return {
              start: Math.round(segment[0] * (seqOptions.current[sequenceName].frameCount - 1)),
              end: Math.round(
                (segment.length > 1 ? segment[1] : segment[0]) * (seqOptions.current[sequenceName].frameCount - 1)
              ),
            };
        },
        get isPlaying() {
          return isPlay.current;
        },
        get isLoop() {
          return isLoop.current;
        },
        get isEnd() {
          return isLastFrame.current;
        },
        get isTransitioning() {
          // Treat unstarted transitions as the state in transition
          return transitions.current.length > 0;
        },
        get currentFrame() {
          return renderFrame.current;
        }
      },
    });
  }, [
    canvasRef,
    sequenceEvents,
    isLastFrame,
    isLoop,
    isPlay,
    progressBreakpoints,
    seqName,
    seqOptions,
    sequences,
    renderFrame,
  ]);

  function setCanvasRefs(el: HTMLCanvasElement | null): void {
    if (typeof forwardedRef === 'function') {
      forwardedRef(el);
    } else if (forwardedRef) {
      forwardedRef.current = el;
    }
    canvasRef.current = el;
    ctxRef.current = el ? el.getContext('2d') : null;
  }

  return <canvas className={styles.canvas} width={frameSize.width} height={frameSize.height} ref={setCanvasRefs} />;
});