import styles from './ShowMoreSlide.module.scss'

export const ShowMoreSlide = ({
  caption,
  desc,
  onClose
}: {
  onClose: () => void,
  caption: TrustedHTML | string,
  desc: TrustedHTML | string,
}) => {

  return <div className={styles.container}>
    <div className={styles.contentWrapper}>
      <span className={styles.contentWrapper__title} dangerouslySetInnerHTML={{ __html: caption }}></span>
      <div className={styles.linksList__item} dangerouslySetInnerHTML={{ __html: desc }}></div>
    </div>
    <button
      type='button'
      className={styles.closeButton}
      onClick={onClose}
    >
      <svg width="63" height="61" viewBox="0 0 63 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M31.1218 31.1213L2.12132 60.1218L0 58.0005L29.0005 29L2.12181 2.12132L4.24313 0L31.1218 26.8787L58.0005 0L60.1218 2.12132L33.2431 29L62.2436 58.0005L60.1223 60.1218L31.1218 31.1213Z" fill="#C4C4C4" /></svg>
    </button>
  </div >
}