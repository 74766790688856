import styles from './Input.module.scss';

export const Input = ({
  type,
  isRequired,
  maxLength,
  id,
  value,
  helperText,
  label,
  name,
  onChange
}: {
  type: string,
  isRequired?: boolean,
  maxLength?: number,
  id?: string,
  value?: string,
  helperText?: string,
  label?: string
  name?: string,
  onChange?: any
}) => {
  return <div className={styles.wrapper}>
    <div className={styles.container}>
      <input
        className={styles.input}
        id={id}
        type={type}
        maxLength={maxLength}
        required={isRequired}
        value={value}
        placeholder={label}
        name={name}
        onChange={onChange}
      />
      <label className={styles.label} htmlFor={id}>{label}</label>
    </div>
    <p className={styles.helperText}>{helperText}</p>
  </div>;
};

