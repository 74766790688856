import { useState, RefObject, useEffect } from "react";

export function useDistanceTo(ref: RefObject<HTMLElement | SVGElement>) {
  const [point, setPoint] = useState({ x: 0, y: 0 });
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    const handlePointerMove = ({ clientX, clientY }: MouseEvent) => {
      const element = ref.current!;
      const coord = element.getBoundingClientRect();

      const x = clientX - coord.x - coord.width / 2;
      const y = clientY - coord.y - coord.height / 2;
      setPoint({ x, y });
      setDistance(Math.sqrt(x * x + y * y));
    };

    window.addEventListener('pointermove', handlePointerMove);

    return () => {
      window.removeEventListener('pointermove', handlePointerMove);
    };
  }, [ref]);

  return { ...point, distance };
}
