import styles from './BurgerMenu.module.scss';
import { TMenu } from "@/api/models/menu.models";
import classNames from "classnames";
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { selectIsOpen } from '@/store/slices/Menu';
import { useMemo, useState } from 'react';
import { BurgerMenuItem } from './BurderMenuItem';

export const BurgerMenu = ({ menu }: { menu: TMenu | undefined }) => {
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const isMenuOpen = useSelector(selectIsOpen);

  const variants = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const itemTransition = {
      duration: 0.3,
      ease: [0.79, 0.09, 0.21, 0.98],
    };
    return {
      container: {
        visible: {
          display: '',
          transition: {
            duration: 0.1
          }
        },
        hidden: {
          display: 'hidden',
          transition: {
            delay: 0.3,
          },
        },
      },
      item: {
        visible: (i: number) => ({
          x: 0,
          transition: {
            // ...itemTransition,
            delay: i * 0.05,
          }
          // transition: itemTransition,
        }),
        hidden: {
          x: 'calc(100% + 30px)',
          // transition: itemTransition,
        },
      },
    }
  }, []);

  function onMenuAnimationComplete(definition: string) {
    setIsMenuHidden(definition === 'hidden' ? true : false);
  }

  return (
    <motion.div
      variants={variants.container}
      initial='hidden'
      animate={isMenuOpen ? 'visible' : 'hidden'}
      onAnimationComplete={onMenuAnimationComplete}
      className={classNames(styles.menuWrapper, {
        [styles.menuWrapper_hidden]: !isMenuOpen && isMenuHidden
      })}
    >
      {
        menu?.map((menuLink, idx) => (
          // The api does not return id, use url as a key instead
          <div
            key={menuLink.url}
            className={classNames(styles.itemWrapper)}
          >
            <motion.div
              custom={idx}
              variants={variants.item}
            >
              <BurgerMenuItem url={`${menuLink.url}`} title={menuLink.title} />
            </motion.div>
          </div>
        ))
      }
    </motion.div>
  )
}